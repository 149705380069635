import Button from '@gameonsports/components/cjs/Button'
import { Stack } from '@gameonsports/components/cjs/StackV2'
import { Text } from '@gameonsports/components/cjs/TextV3'
import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import ThemeContext from '../../contexts/ThemeContext/ThemeContext'
import { useAppFooterBannerQuery } from '../../generated/graphql'
import PlayAFLIcon from '../../images/app/appIconAFL.png'
import MyHoopsIcon from '../../images/app/appIconMyHoops.png'
import NetballHQIcon from '../../images/app/appIconNetballHQ.png'
import { getMobileOperatingSystem } from '../../utils/device'
import { isSSR } from '../../utils/ssr'
import { track } from '../../utils/track'

const iconMap: Record<string, string | undefined> = {
  playafl: PlayAFLIcon,
  myhoops: MyHoopsIcon,
  netballhq: NetballHQIcon,
}

const APP_FOOTER_BANNER_CLOSED_APPS_KEY = 'appFooterBannerClosedApps'

const getClosedApps = () => {
  if (isSSR) return []
  const storage = sessionStorage.getItem(APP_FOOTER_BANNER_CLOSED_APPS_KEY)
  return storage?.split(',') ?? []
}

const setClosedApp = (closedApp: string) => {
  if (isSSR) return
  const closedApps = getClosedApps()
  sessionStorage.setItem(
    APP_FOOTER_BANNER_CLOSED_APPS_KEY,
    closedApps.concat(closedApp).join(','),
  )
}

export const AppFooterBanner = ({
  tenant,
  sport,
}: {
  tenant: string
  sport?: string
}) => {
  const [open, setOpen] = useState(false)
  const closedApps = useMemo(getClosedApps, [])
  const { isWebview } = useContext(ThemeContext)

  const os = getMobileOperatingSystem()
  const skip = isSSR || !os || !sport

  const { data } = useAppFooterBannerQuery({
    context: {
      headers: {
        tenant,
      },
    },
    onError: () => null,
    skip,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const appValue = data.tenantConfiguration?.outswingConfiguration?.value
      if (appValue && !closedApps.includes(appValue)) {
        setOpen(true)
      }
    },
  })

  if (isWebview) return null

  const config = data?.tenantConfiguration?.outswingConfiguration
  const appValue = config?.value
  if (skip || !open || !config?.value || !appValue) return null

  const link =
    os === 'iOS'
      ? config.urls?.appleAppStore
      : os === 'Android'
      ? config.urls?.googlePlayStore
      : undefined
  const sportLower = sport.toLowerCase()
  const sportName = sportLower === 'afl' ? 'footy' : sportLower
  const iconImage = iconMap[appValue]
  if (!link || !iconImage) return null

  return (
    <Container>
      <Stack padding="m" direction="row" gap="m" alignItems="center">
        <Button
          aria-label="Close App Banner"
          icon="cross"
          variant="text"
          evenPadding
          iconSize="12"
          style={{ padding: 6 }}
          onClick={() => {
            setOpen(false)
            setClosedApp(appValue)
          }}
        />
        <IconImage src={iconImage} alt="Play AFL" />
        <Stack style={{ flex: 1 }}>
          <Text size="16" weight="700">
            {config.name} App
          </Text>
          <Text size="12" weight="400">
            Follow community {sportName}
          </Text>
        </Stack>
        <Button
          as="a"
          href={link}
          icon="right-arrow-thin"
          iconPosition="right"
          target="_blank"
          rel="noopener noreferrer"
          size="small"
          onClick={() =>
            track({ event: `click_app_footer_banner_${appValue}` })
          }
        >
          Go
        </Button>
      </Stack>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white400};
  border-top: solid 1px ${({ theme }) => theme.colors.lightGrey400};
`

const IconImage = styled.img`
  width: 3.25rem;
  height: 3.25rem;
`
